import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "page",
    loadChildren: () =>
      import("../../page/page.module").then((m) => m.PageModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "company",
    loadChildren: () =>
      import("../../pages/ok-company/ok-company.module").then(
        (m) => m.OkCompanyModule
      ),
  },
  {
    path: "supplier",
    loadChildren: () =>
      import("../../pages/ok-suppliers/ok-supplier.module").then(
        (m) => m.OkSupplierModule
      ),
  },
  {
    path: "companygroup",
    loadChildren: () =>
      import("../../pages/company-group/company-group.module").then(
        (m) => m.CompanyGroupModule
      ),
  },
  {
    path: "userb",
    loadChildren: () =>
      import("../../pages/ok-admin/ok-admin.module").then(
        (m) => m.OkAdminModule
      ),
  },
  {
    path: "userbgroup",
    loadChildren: () =>
      import("../../pages/user-b-group/user-b-group.module").then(
        (m) => m.UserBGroupModule
      ),
  },
  {
    path: "financialinfo",
    loadChildren: () =>
      import("../../pages/financial-info/financial-info.module").then(
        (m) => m.FinancialInfoModule
      ),
  },
  {
    path: "invoiceInfo",
    loadChildren: () =>
      import("../../pages/invoice-info/invoice-info.module").then(
        (m) => m.InvoiceInfoModule
      ),
  },
  {
    path: "report",
    loadChildren: () =>
      import("../../pages/report/synthetic-report.module").then(
        (m) => m.SyntheticReportModule
      ),
  },
];
