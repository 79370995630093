import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import firebase from 'firebase/app'
import {Observable} from 'rxjs';
import {StorageUtils} from '../../utils/storage-utils';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;

  constructor(public router: Router,
              public storageUtils: StorageUtils) {

  }

  logout() {
    this.storageUtils.storeToken(null);
    this.storageUtils.idUser(null);
    this.storageUtils.nameUser(null);
    this.storageUtils.roleId(null);
    this.storageUtils.storeAutoLogin(null);
    window.location.href = environment.arktecLoginSite + '/logout?app=FINANCE&u=http://' + window.location.host + '/check'
  }

  login() {
    this.storageUtils.redirectUrl(window.location.href)
    window.location.href = environment.arktecLoginSite + '/check?app=FINANCE&u=http://' + window.location.host + '/check'
  }

  isAuthenticated() {
    return this.storageUtils.getToken() != null && this.storageUtils.getToken() !== 'null';
  }

  getToken() {
    return this.storageUtils.getToken()
  }
}
