import {Component, Inject, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from '../../shared/services/window.service';
import {ActivatedRoute} from '@angular/router';
import {StorageUtils} from '../../utils/storage-utils';
import {UserBService} from '../ok-admin/user-b/user-b-service';
import {UserB} from '../ok-admin/user-b/user-b';
import {environment} from '../../../environments/environment';
import {ROUTES_ADMIN, ROUTES_OPER} from '../../shared/vertical-menu/vertical-menu-routes.config';

@Component({
    selector: 'validation-steps-page',
    templateUrl: './check-token-page.component.html',
    styleUrls: ['./check-token-page.component.scss'],
    providers: [

    ],
    encapsulation: ViewEncapsulation.None
})
export class CheckTokenPageComponent implements OnInit {

    constructor(@Inject(DOCUMENT) private document: Document,
                @Inject(WINDOW) private window: Window,
                private renderer: Renderer2,
                private activatedRoute: ActivatedRoute,
                private storage: StorageUtils,
                private userBService: UserBService) {

        this.activatedRoute.queryParams.subscribe(params => {
            const token = params['t'];
            storage.storeToken(token)
        });
    }

    ngOnInit(): void {
        this.renderer.removeClass(this.document.body, 'overflow-hidden');
        this.renderer.removeClass(this.document.body, 'layout-dark');
        this.renderer.removeClass(this.document.body, 'layout-transparent');

        if (this.storage.getToken() == null) {
            this.redirectToLogin()
            return
        }

        this.userBService.userBSession((resultS, userS) => {
            if (userS != null) {
                this.saveuserData(userS)
            } else {
                const userB = this.storage.getAutoLogin();
                if (userB != null) {
                    this.userBService.autoLogin(userB, (result, user) => {
                        if (result.success) {
                            this.saveuserData(user)
                        } else {
                            this.redirectToLogin()
                        }
                    });
                } else {
                    this.redirectToLogin()
                }
            }
        });
    }

    redirectToLogin() {
        this.renderer.removeClass(this.document.body, 'loadind-body');
        window.location.href = environment.arktecLoginSite + '/check?app=FINANCE&u=http://' + this.window.location.host + '/check'
    }

    saveuserData(user: UserB) {
        this.storage.storeToken(user.token);
        this.storage.idUser(user.id);
        this.storage.nameUser(user.name);
        this.storage.roleId(user.idRole);
        this.storage.urlImage(user.urlImage);
        this.storage.storeLoggedUser(user);

        if (this.storage.getRedirectUrl() != null && this.storage.getRedirectUrl() !== 'null') {
            window.location.href = this.storage.getRedirectUrl()
            this.storage.redirectUrl(null)
        } else {
            if (user.idRole === 'admin') {
                if(ROUTES_ADMIN[0].path != ''){
                    this.storage.urlHome(ROUTES_ADMIN[0].path);
                    window.location.href = ROUTES_ADMIN[0].path;
                } else {
                    this.storage.urlHome(ROUTES_ADMIN[0].submenu[0].path);
                    window.location.href = ROUTES_ADMIN[0].submenu[0].path;
                }
            }

            if (user.idRole === 'oper') {
                if(ROUTES_OPER[0].path != ''){
                    this.storage.urlHome(ROUTES_OPER[0].path);
                    window.location.href = ROUTES_OPER[0].path;
                } else {
                    this.storage.urlHome(ROUTES_OPER[0].submenu[0].path);
                    window.location.href = ROUTES_OPER[0].submenu[0].path;
                }
            }
        }
    }
}
