import {RouteInfo} from './vertical-menu.metadata';


export const ROUTES_ADMIN: RouteInfo[] = [
    {
        path: '/invoiceInfo/search',
        title: 'Faturamento',
        icon: 'ft-credit-card',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    // {
    //     path: 'report',
    //     title: 'Relatórios',
    //     icon: 'ft-file',
    //     class: 'has-sub',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     submenu: [
    //         {
    //             path: '/report/synthetic',
    //             title: 'Relatório de Faturamento Sintético',
    //             icon: 'ft-file',
    //             class: '',
    //             badge: '',
    //             badgeClass: '',
    //             isExternalLink: false,
    //             submenu: []
    //         },
    //     ]
    // },
];

export const ROUTES_OPER: RouteInfo[] = [
    {
        path: '/page', title: 'Workspace', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
    }
];
